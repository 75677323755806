import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import { useDetachedState } from '@/components/hooks/use-detached-state';

export interface CaseStudyRootProps {
  link?: string;
  title?: string;
}

export function CaseStudyRoot({ link, title }: CaseStudyRootProps) {
  const [height, setHeight] = useDetachedState(`100vh`);

  useEffect(() => {
    const handler = (ev) => {
      if (ev?.data?.type === `iframeHeightChange`) {
        setHeight(`${ev.data.height + 20}px`);
      }
    };
    window.addEventListener(`message`, handler);
    return () => {
      window.removeEventListener(`message`, handler);
    };
  }, []);

  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <EmbeddableCaseStudy
        style={{ height }}
        id="hubspot-cs"
        title={title}
        src={link}
      />
      <Footer />
    </PageWrapper>
  );
}

const EmbeddableCaseStudy = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none;
`;
